import React from "react";

import Header from "../components/Header";
import GalleryCarousel from "../components/GalleryCarousel";

function Gallery() {
    return (
        <main>
            <Header/>
            <GalleryCarousel/>
        </main>
    )
}

export default Gallery;