import React, { useState } from "react";

import "../styles/App.css";
import "../styles/Hero.css";

function Hero() {
    const [copyIP_button_text, changeText] = useState("IP: NisLand.tk");
    function copyIP() {
        changeText("Copied");
        navigator.clipboard.writeText("NisLand.tk:25565");
        setTimeout(() => {changeText("IP: NisLand.tk")}, 1500);
    }

    return (
        <section className="hero">
            <div className="hero__main-block block">
                <h1 className="block__title">
                    Unique<br></br>
                    Minecraft<br></br>
                    Server
                </h1>
                <p className="block__text">
                    NL is a separate world.<br></br>
                    Without griefers, privates, donates.
                </p>
                <div className="block__buttons buttons">
                    <a href="#servers" className="buttons__servers">Our Servers</a>
                    <div className="buttons__copy-ip" onClick={copyIP}>{copyIP_button_text}</div>
                </div>
            </div>
            <div className="hero__decor-pic">
                <img src={process.env.PUBLIC_URL + "/img/bg-hero.png"} alt="dec-img"/>
            </div>
        </section>
    )
}

export default Hero;