import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Mods from "./pages/Mods";
import Gallery from "./pages/Gallery";
import Events from "./pages/Events";

function App() {
	return (
		<Routes>
			<Route exact path="/" element={<Home/>} />
			<Route path="/home" element={<Home/>} />
			<Route path="/mods" element={<Mods/>} />
			<Route path="/gallery" element={<Gallery/>} />
			<Route path="/events" element={<Events/>} />
		</Routes>
	);
}

export default App;
