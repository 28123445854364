import React from "react";

import Header from "../components/Header";
import EventShow from "../components/EventShow";

function Events() {
    return (
        <main>
            <Header/>
            <EventShow/>
        </main>
    )
}

export default Events;