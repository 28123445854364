import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";

import eventsData from "../eventsData.json";

import "../styles/App.css";
import "../styles/Events.css";
import "../styles/Gallery.css"

function EventShow() {
    let eventIndex = 0;

    const [hours, setHour] = useState(0);
    const [minutes, setMinute] = useState(0);
    const [seconds, setSecond] = useState(0);
    const [todaysDate, setToday] = useState(0);
    const [output, setOut] = useState("");

    let date = new Date(eventsData[eventIndex].date[0], eventsData[eventIndex].date[1] - 1, eventsData[eventIndex].date[2], eventsData[eventIndex].date[3], eventsData[eventIndex].date[4], eventsData[eventIndex].date[5]);

    if ((date - todaysDate) >= 0) {
        setInterval(() => {
            setToday(new Date());
            setHour(Math.floor(((date - todaysDate) / 1000) / 3600));
            setMinute(Math.floor(((date - todaysDate) / 1000) % 3600 / 60));
            setSecond(Math.floor(((date - todaysDate) / 1000) % 3600 % 60));
    
            setOut((hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes) + ":" + (seconds < 10 ? ("0" + seconds) : seconds));
        }, 1100)
    }

    return (
        <section className="events">
            <div className="events__container">
                <div className="event__block eventBlock">    
                    <div className="eventBlock__left left-general">
                        <h1 className="left-general__title">{eventsData[eventIndex].eventName}</h1>
                        <p className="left-general__text" dangerouslySetInnerHTML={{__html: eventsData[eventIndex].desciption}} ></p>
                    </div>
                    <img className="eventBlock__right" src={eventsData[eventIndex].pictureLink} alt="event-pic" />
                </div>
                <div className="event__timer">
                    <span>{(date - todaysDate) <= 0 ? "EXPIRED" : output}</span>
                </div>
            </div>
        </section>
    )
}

export default EventShow;

