import React from "react";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Servers from "../components/Servers";

function Home() {
    return (
        <main>
            <Header/>
            <Hero/>
            <Servers/>
        </main>
    )
}

export default Home;