import React from "react";

import "../styles/App.css"
import "../styles/Servers.css"

function Servers() {
    
    return (
        <section id="servers" className="servers">
            <h1 className="servers__title">NisLand Servers</h1>
            <div className="servers__container block-servers">
                <div className="block-servers__item">
                    <h1><img src={process.env.PUBLIC_URL + "/img/icon/java.svg"} alt="icon-java"/>NL</h1>
                    <p>
                        NL - classic vanilla minecraft server, without mods that strongly affecting the game mechanics. No need to buy license. Server for Minecraft Java edition.
                    </p>
                    <h4>IP: NisLand.tk:25565</h4>
                    <a rel="noopener noreferrer" href="https://t.me/minecraft_nis" target="_blank" className="block-servers__button">More details</a>
                </div>
                <div className="block-servers__item">
                    <h1><img src={process.env.PUBLIC_URL + "/img/icon/c++.svg"} alt="icon-c++"/>NL Bedrock</h1>
                    <p>
                        NLb - vanilla server for mobile devices(Also for win10 edition, nintendo, ps and other cross-platform devices). It will open soon.
                    </p>
                    <h4>IP:NisLand.tk:19132</h4>
                    <a rel="noopener noreferrer" href="https://t.me/minecraft_nis" target="_blank" className="block-servers__button">More details</a>
                </div>
                <div className="block-servers__item">
                    <h1><img src={process.env.PUBLIC_URL + "/img/icon/mod.svg"} alt="icon-mod"/>NL mod</h1>
                    <p>
                        NLm - server with mod builds. It will open soon.
                    </p>
                    <h4>IP:NisLand.tk:35565</h4>
                    <a rel="noopener noreferrer" href="https://t.me/minecraft_nis" target="_blank" className="block-servers__button">More details</a>
                </div>
            </div>
        </section>
    )
}

export default Servers;