import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../styles/App.css";
import "../styles/Header.css";

function Header() {
    const [burger_active, setBurger] = useState("");
    const [navbar_active, setNavbar] = useState("");
    return (
        <header className="header">
            <div className="header__logo">
                <Link to="/home"><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="Logo"/>NisLand</Link>
            </div>
            <div className="header__nav header-navbar">
                <ul className={"header-navbar__body " + navbar_active}>
                    <Link to="/gallery" className="header-navbar__item">Gallery<img src={process.env.PUBLIC_URL + '/img/icon/albums.svg'} alt="gallery-icon"/></Link>
                    <Link to="/mods" className="header-navbar__item">Mods<img src={process.env.PUBLIC_URL + '/img/icon/mod.svg'} alt="mods-icon"/></Link>
                    <Link to="/events" className="header-navbar__item">Events<img src={process.env.PUBLIC_URL + '/img/icon/events.svg'} alt="events-icon"/></Link>
                    {/* <Link to="/players" className="header-navbar__item">Players<img src={process.env.PUBLIC_URL + '/img/icon/user.svg'} alt="players-icon"/></Link> */}
                </ul>
                <div className={"burger__menu " + burger_active} onClick={() => {
                    burger_active === "" ? setBurger("active-burger") : setBurger("");
                    navbar_active === "" ? setNavbar("active-navbar-menu") : setNavbar("");
                }} >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    )
}

export default Header;