import React, { useState } from "react";

import "../styles/App.css";
import "../styles/Mods.css";

import modsList from "../allowedModsData.json";

function ListMods() {
    const [searchText, setSerchText] = useState("");

    return (
        <section className="mods" id="mods">
            <div className="mods__container container-element">
                <h2 className="container-element__title">Mods</h2>
                <div className="container-element__text-search">
                    <p className="container-element__text">List of modifications that allowed in NL.<br />This list for Fabric minecraft.</p>
                    <div>
                        <img src={process.env.PUBLIC_URL + "/img/icon/search.svg"} alt="search-icon"/>
                        <input className="container-element__search" id="live-search" type="text" placeholder="Mod name" onChange={(text) => setSerchText(text.target.value)} maxLength={32}/>
                    </div>
                    
                </div>
                <div className="container-element__block mods-block">
                    {
                        modsList.filter((mod) => {
                            if (searchText === "") {
                                return mod;
                            }
                            else if (mod.name.toLowerCase().split(" ").join("").includes(searchText.split(" ").join("").toLowerCase())) {
                                return mod;
                            }
                        }).map((mod, key) => {
                            return (
                                <div key={key} id="mod-block" className="mods-block__item">
                                    <img src={mod.img} alt="mod-icon" loading="lazy" width={128}/>
                                    <h2 id="mod-name">{mod.name}</h2>
                                    <p>{mod.description}</p>
                                    <a href={mod.link} rel="noopener noreferrer" target="_blank">Download</a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default ListMods;