import React from "react";

import Header from "../components/Header";
import ListMods from "../components/ListMods";

function Mods() {
    return (
        <main>
            <Header/>
            <ListMods/>
        </main>
    )
}

export default Mods;