import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCube } from "swiper";

import "../modules/swiperjs/swiper-bundle.min.css";
import "../styles/App.css";
import "../styles/Gallery.css";

function GalleryCarousel() {
    const [seasonNum, setSeasonNum] = useState(1);
    let currentSeason = 2;

    seasonNum < 1 ? setSeasonNum(currentSeason) : console.log("");
    seasonNum > currentSeason ? setSeasonNum(1) : console.log("");

    function increase(bool) {
        bool === true ? setSeasonNum(seasonNum + 1) : setSeasonNum(seasonNum - 1);
    }

    let fix = []
    let seasonLenght = [16, 0]
    for (let i = 1; i <= seasonLenght[seasonNum - 1]; i++) {
        fix.push(i);
    }

    function gallery_show() {
        if (seasonNum === currentSeason) {
            return (
                <h1 className="gallery__text">The season is not over yet</h1>
            )
        }
    }

    return (
        <section className="gallery">
            <div className="gallery__container">
                <div className="gallery__switch">
                    <img src={process.env.PUBLIC_URL + "/img/icon/arrowLeft.svg"} alt="arrow-left" onClick={() => increase(false)}/>
                    <h1 className="gallery__title">Season {seasonNum}</h1>
                    <img src={process.env.PUBLIC_URL + "/img/icon/arrowRight.svg"} alt="arrow-right" onClick={() => increase(true)}/>
                </div>
                <div className="gallery__body">
                    <Swiper
                        modules={[Pagination, EffectCube]}
                        slidesPerView={1}
                        loop={true}
                        effect={"cube"}
                        pagination={{clickable: true}}
                    >
                        {
                            gallery_show()
                        }
                        {
                            fix.map((index, key) => {
                                return (
                                    <SwiperSlide key={key}><img src={process.env.PUBLIC_URL + "/img/gallery/season" + seasonNum + "/" + index + ".webp"} alt={"img-" + index} className="gallery__img" loading="lazy" /></SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default GalleryCarousel;